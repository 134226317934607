import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Button, Image, OverlayTrigger, Tooltip, Badge, Card, Row, Col } from 'react-bootstrap';
import { Code, ArrowLeft, Info } from 'lucide-react';
import { AppContext } from '../AppContext';
import '../App.css';

const userFriendlyTitles = {
  'image_vs_image': 'Visual Match',
  'image_vs_filename': 'Name Match',
  'text_vs_image': 'Keyword Match',
  'text_vs_filename': 'Description-Name Match',
  'combined_similarity': 'Overall Match'
};

const ManufacturerResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const results = location.state?.results;
  const userSubmittedImage = location.state?.userSubmittedImage;
  const userSubmittedPrompt = location.state?.userSubmittedPrompt;
  const [isDeveloperView, setIsDeveloperView] = useState(false);
  const { isDark } = useContext(AppContext);

  const toggleView = () => setIsDeveloperView(!isDeveloperView);

  const handleBack = () => navigate('/sketch');

  const renderInfoCard = () => (
    <Card className={`mt-4 ${isDark ? 'bg-dark text-light' : 'bg-light'} shadow-sm`}>
      <Card.Body>
        <div className="d-flex align-items-center mb-3">
          <Info size={24} className="me-2" />
          <h3 className="mb-0" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Understanding Your Results</h3>
        </div>
        <p className="mb-2">
          Above, you'll find a list of manufacturers whose designs closely match your submission. 
          Each result shows a sample of the manufacturer's work and a similarity score based on your submission. 
        </p>
        <p className="mb-0">
          To proceed, review the matches, select a manufacturer that best fits your needs, and use their 
          name to find their contact information online. We're working on a direct messaging system to 
          make this process even easier in the future!
        </p>
      </Card.Body>
    </Card>
  );

  if (!results || !userSubmittedImage) {
    return (
      <Container className="py-5">
        <h1 className={`mb-4 ${isDark ? 'text-light' : ''} display-4`}>Manufacturer Results</h1>
        <Card className={`p-5 text-center ${isDark ? 'bg-dark text-light' : 'bg-light'}`}>
          <Card.Body>
            <p className="mb-4 fs-5">No results available. Please submit a tech pack first.</p>
            <Button variant={isDark ? "outline-light" : "primary"} size="lg" onClick={handleBack}>
              Go to Tech Pack Upload
            </Button>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  const renderResultItem = (key, value) => (
    <Card key={key} className={`mb-4 ${isDark ? 'bg-dark text-light' : 'bg-white'} shadow-sm result-card`}>
      <Card.Body>
        <Row className="align-items-center">
          <Col xs={4} sm={3} md={2} className="pe-0">
            <Image
              src={`data:image/jpeg;base64,${value.base64_encoding}`}
              alt={`${value.manufacturer} sample`}
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
              className="rounded"
            />
          </Col>
          <Col xs={8} sm={9} md={10}>
            <h4 className="mb-2">{value.manufacturer}</h4>
            <Badge bg={isDark ? "light" : "primary"} text={isDark ? "dark" : "light"} className="mb-2">
              {isDeveloperView ? key.toUpperCase().replace(/_/g, ' ') : userFriendlyTitles[key] || key.replace(/_/g, ' ').toUpperCase()}
            </Badge>
            {isDeveloperView && (
              <div className="mt-2 small">
                <p className="mb-1">
                  <strong>Similarity Score:</strong> {value.similarity.toFixed(4)}
                </p>
                <p className="mb-0 text-muted">Match type: {key}</p>
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

  return (
    <Container className="py-5 position-relative">
      <style>
        {`
          .border-custom-blue {
            border: 4px solid #3a90e5;
          }
          .text-custom-light {
            color: #000000;
          }
          .text-custom-dark {
            color: #ffffff;
          }
          .result-card {
            transition: all 0.3s ease;
            cursor: pointer;
          }
          .result-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
          }
          .back-button {
            position: absolute;
            top: 20px;
            left: 15px;
            z-index: 1000;
          }
          .main-header {
            font-size: 2.5rem;
            font-weight: bold;
          }
          .sub-header {
            font-size: 1.8rem;
            font-weight: 600;
          }
          .description-text {
            font-size: 1rem;
            margin-bottom: 1rem;
          }
          .compare-text {
            font-size: 1rem;
            margin-top: 1rem;
          }
        `}
      </style>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className={`mb-0 ${isDark ? 'text-light' : ''} main-header`}>Manufacturer Results</h1>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="developer-view-tooltip">Toggle Developer View</Tooltip>}
        >
          <Button
            variant="link"
            onClick={toggleView}
            className={`p-0 ${isDark ? 'text-light' : 'text-dark'}`}
            style={{ opacity: 0.7 }}
          >
            <Code size={24} />
          </Button>
        </OverlayTrigger>
      </div>
      
      <Card className={`mb-4 border-custom-blue ${isDark ? 'bg-dark text-custom-dark' : 'bg-white text-custom-light'} shadow-sm`}>
        <Card.Body>
          <Row className="align-items-center">
            <Col xs={12} sm={3} md={2} className="pe-0 mb-3 mb-sm-0">
              <Image
                src={`data:image/jpeg;base64,${userSubmittedImage}`}
                alt="Your submitted design"
                style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                className="rounded"
              />
            </Col>
            <Col xs={12} sm={9} md={10}>
              <h4 className="mb-2 sub-header">Your Design</h4>
              {userSubmittedPrompt && (
                <p className="mb-3 description-text"><strong>Your Description:</strong> {userSubmittedPrompt}</p>
              )}
              <p className="mb-0 compare-text">Compare and select the manufacturer with the most similar design as yours.</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      
      <div className="mb-4">
        {Object.entries(results).map(([key, value]) => renderResultItem(key, value))}
      </div>
      
      {renderInfoCard()}
    </Container>
  );
};

export default ManufacturerResults;