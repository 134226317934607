// APIService.js
import axios from 'axios';
import { useContext } from 'react';
import { AppContext } from '../AppContext';

const BASE_URL = "https://prodapi.pomu.io" //"http://localhost:8000" for Development

const useAPIService = () => {
  const { getAuthToken } = useContext(AppContext);

  const SubmitUserRequest = async (formData) => {
    try {
      const token = await getAuthToken();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      const response = await axios.post(`${BASE_URL}/api/submit-user-request/`, formData, { headers });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const SubmitTechPack = async (formData) => {
    try {
      const token = await getAuthToken();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      const response = await axios.post(`${BASE_URL}/api/submit-tech-pack/`, formData, { headers });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const SubmitContactForm = async (formData) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };
      const response = await axios.post(`${BASE_URL}/api/submit-contact-form/`, formData, { headers });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    SubmitUserRequest,
    SubmitTechPack,
    SubmitContactForm,
  };
};

export default useAPIService;