import React, { useContext, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Button, Image, Dropdown } from 'react-bootstrap';
import { LogOut, Sun, Moon, User } from 'lucide-react';
import { AppContext } from '../AppContext';
import '../App.css'; // Import styles from app.css

const CUSTOM_BG_LIGHT = '#e6eef7';  // A more noticeable light grayish-blue
const CUSTOM_BG_DARK = '#1e2a38';   // A slightly darker slate color

const GlobalNavbar = () => {
  const { 
    isDark, 
    toggleDarkMode, 
    isAuthenticated, 
    handleLogout, 
    userEmail,
  } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const navLinks = useMemo(() => [
    { path: '/home', label: 'Home', showAlways: true },
    { path: '/sketch', label: 'Upload', requireAuth: true },
    // Add more nav links here
  ], []);

  const filteredNavLinks = navLinks.filter(link => 
    link.showAlways || (link.requireAuth === isAuthenticated)
  );

  const handleLogin = () => {
    navigate('/login');
  };

  const logoSrc = isDark 
    ? require("../images/logo/pomu-logo-invert.png") 
    : require("../images/logo/pomu-logo.png");

  // Custom styles for the dropdown
  const dropdownStyles = {
    toggle: {
      backgroundColor: isDark ? CUSTOM_BG_DARK : CUSTOM_BG_LIGHT,
      color: isDark ? '#fff' : '#333',
      borderColor: isDark ? '#4a6785' : '#b8c9e0',
    },
    menu: {
      backgroundColor: isDark ? CUSTOM_BG_DARK : CUSTOM_BG_LIGHT,
      borderColor: isDark ? '#4a6785' : '#b8c9e0',
    },
    item: {
      color: isDark ? '#fff' : '#333',
      '&:hover': {
        backgroundColor: isDark ? '#2c3e50' : '#d1dff0',
        color: isDark ? '#ffffff' : '#000000', // Ensure text is visible on hover
      },
    },
  };

  return (
    <Navbar 
      expand="lg" 
      className="py-3"
      style={{
        backgroundColor: isDark ? CUSTOM_BG_DARK : CUSTOM_BG_LIGHT,
        color: isDark ? '#fff' : '#333',
      }}
    >
      <Container>
        <Navbar.Brand as={Link} to="/home">
          <Image src={logoSrc} alt="Pomu Logo" fluid style={{ maxWidth: '60px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {filteredNavLinks.map((link) => (
              <Nav.Link 
                key={link.path} 
                as={Link} 
                to={link.path} 
                active={location.pathname === link.path}
                style={{
                  color: isDark ? '#fff' : '#333',
                  '&:hover': {
                    color: isDark ? '#b8c9e0' : '#1e2a38',
                  },
                }}
              >
                {link.label}
              </Nav.Link>
            ))}
          </Nav>
          <Nav>
            {isAuthenticated ? (
              <Dropdown align="end">
                <Dropdown.Toggle
                  style={dropdownStyles.toggle}
                  id="user-dropdown"
                >
                  <User size={18} className="me-2" />
                  {userEmail}
                </Dropdown.Toggle>
                <Dropdown.Menu style={dropdownStyles.menu}>
                  <Dropdown.Item 
                    as={Link} 
                    to="/profile" 
                    style={dropdownStyles.item}
                    className="dropdown-item-custom"
                  >
                    <User size={18} className="me-2" />
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Divider style={{ borderColor: isDark ? '#4a6785' : '#b8c9e0' }} />
                  <Dropdown.Item 
                    onClick={handleLogout} 
                    style={dropdownStyles.item}
                    className="dropdown-item-custom"
                  >
                    <LogOut size={18} className="me-2" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <>
                <Button 
                  variant={isDark ? "outline-light" : "outline-dark"} 
                  onClick={handleLogin} 
                  className="me-2"
                >
                  Login
                </Button>
                <Button 
                  as={Link} 
                  to="/create-account" 
                  variant={isDark ? "light" : "dark"}
                >
                  Sign Up
                </Button>
              </>
            )}
            <Button 
              variant="link" 
              onClick={toggleDarkMode} 
              className="ms-2"
              style={{
                color: isDark ? '#fff' : '#333',
              }}
            >
              {isDark ? <Sun size={18} /> : <Moon size={18} />}
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default GlobalNavbar;