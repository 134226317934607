import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Card, Alert, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { Upload, Sun, Moon } from 'lucide-react';
import APIService from './APIService';
import '../App.css';
import { AppContext } from '../AppContext';

const PomuForm = () => {
  const navigate = useNavigate();
  const { isDark, isAuthenticated } = useContext(AppContext);
  const [formData, setFormData] = useState({
    totalUnits: '',
    location: '',
    productionTiming: 'Standard',
    materials: [],
    patterns: []
  });
  const [file, setFile] = useState(null);
  const [submitStatus, setSubmitStatus] = useState({ message: '', type: '' });
  const [suggestedManufacturers, setSuggestedManufacturers] = useState([]);

  const locationOptions = [
    { value: 'China', label: 'China' },
    { value: 'Hong Kong S.A.R.', label: 'Hong Kong S.A.R.' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'India', label: 'India' },
    { value: 'Thailand', label: 'Thailand' },
  ];

  const materialOptions = [
    { value: 'cotton', label: 'Cotton' },
    { value: 'polyester', label: 'Polyester' },
    { value: 'wool', label: 'Wool' },
    { value: 'silk', label: 'Silk' },
    { value: 'leather', label: 'Leather' },
    { value: 'denim', label: 'Denim' },
  ];

  const patternOptions = [
    { value: 'solid', label: 'Solid' },
    { value: 'striped', label: 'Striped' },
    { value: 'plaid', label: 'Plaid' },
    { value: 'floral', label: 'Floral' },
    { value: 'polka-dot', label: 'Polka Dot' },
    { value: 'geometric', label: 'Geometric' },
  ];

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    setFormData(prevState => ({
      ...prevState,
      [actionMeta.name]: selectedOption ? selectedOption.value : ''
    }));
  };

  const handleMultiSelectChange = (selectedOptions, actionMeta) => {
    setFormData(prevState => ({
      ...prevState,
      [actionMeta.name]: selectedOptions.map(option => option.value)
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'image/jpeg') {
      setFile(selectedFile);
      setSubmitStatus({ message: '', type: '' });
    } else {
      setFile(null);
      setSubmitStatus({ message: 'Please select a JPEG file.', type: 'danger' });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus({ message: '', type: '' });
    setSuggestedManufacturers([]);

    if (!file) {
      setSubmitStatus({ message: 'Please select a JPEG file to upload.', type: 'danger' });
      return;
    }

    const submitData = new FormData();
    submitData.append('file', file);
    
    Object.keys(formData).forEach(key => {
      if (Array.isArray(formData[key])) {
        submitData.append(key, JSON.stringify(formData[key]));
      } else {
        submitData.append(key, formData[key]);
      }
    });

    try {
      const response = await APIService.SubmitUserRequest(submitData);
      console.log('Form submitted:', response);
      setSubmitStatus({ message: 'Submission successful!', type: 'success' });
      
      if (response.top_manufacturers) {
        setSuggestedManufacturers(response.top_manufacturers);
      }

      setFile(null);
      setFormData({
        totalUnits: '',
        location: '',
        productionTiming: 'Standard',
        materials: [],
        patterns: []
      });
    } catch (error) {
      console.error('Failed to submit form:', error);
      setSubmitStatus({ message: 'Failed to submit. Please try again.', type: 'danger' });
    }
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDark ? '#333' : '#fff',
      borderColor: isDark ? '#666' : '#ced4da',
      color: isDark ? '#fff' : '#000',
      '&:hover': {
        borderColor: isDark ? '#999' : '#80bdff'
      }
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDark ? '#333' : '#fff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: isDark
        ? state.isSelected
          ? '#0056b3'
          : state.isFocused
          ? '#4a4a4a'
          : '#333'
        : state.isSelected
        ? '#007bff'
        : state.isFocused
        ? '#f8f9fa'
        : '#fff',
      color: isDark ? '#fff' : '#000',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDark ? '#fff' : '#000',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: isDark ? '#666' : '#e9ecef',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: isDark ? '#fff' : '#000',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: isDark ? '#fff' : '#000',
      ':hover': {
        backgroundColor: isDark ? '#999' : '#dee2e6',
        color: isDark ? '#fff' : '#000',
      },
    }),
  };

  return (
    <div className={`App ${isDark ? 'dark-mode' : ''}`}>
      <Container className="py-5" style={{ maxWidth: '800px' }}>
        <Card className={`shadow-lg border-0 ${isDark ? 'bg-dark text-light' : ''}`}>
          <Card.Header className={`py-3 ${isDark ? 'bg-dark' : 'bg-primary text-white'}`}>
            <h2 className="mb-0 fs-4">PomuForm</h2>
          </Card.Header>
          <Card.Body className="px-4 py-5">
            {submitStatus.message && (
              <Alert variant={submitStatus.type} className={`mb-4 ${isDark ? 'bg-dark' : ''}`}>
                {submitStatus.message}
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className={`fw-bold ${isDark ? 'text-light' : ''}`}>Total Units</Form.Label>
                    <Form.Control
                      type="number"
                      name="totalUnits"
                      value={formData.totalUnits}
                      onChange={handleInputChange}
                      required
                      className={`py-2 ${isDark ? 'bg-dark text-light' : ''}`}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label className={`fw-bold ${isDark ? 'text-light' : ''}`}>Location</Form.Label>
                    <Select
                      name="location"
                      options={locationOptions}
                      value={locationOptions.find(option => option.value === formData.location)}
                      onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'location' })}
                      required
                      styles={customSelectStyles}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label className={`fw-bold ${isDark ? 'text-light' : ''}`}>Production Timing</Form.Label>
                    <Form.Select
                      name="productionTiming"
                      value={formData.productionTiming}
                      onChange={handleInputChange}
                      className={`py-2 ${isDark ? 'bg-dark text-light' : ''}`}
                    >
                      <option value="Urgent">Urgent (1-2 weeks)</option>
                      <option value="Standard">Standard (3-4 weeks)</option>
                      <option value="Flexible">Flexible (1-2 months)</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-4">
                    <Form.Label className={`fw-bold ${isDark ? 'text-light' : ''}`}>Materials</Form.Label>
                    <Select
                      isMulti
                      name="materials"
                      options={materialOptions}
                      value={materialOptions.filter(option => formData.materials.includes(option.value))}
                      onChange={(selectedOptions, actionMeta) => handleMultiSelectChange(selectedOptions, actionMeta)}
                      styles={customSelectStyles}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label className={`fw-bold ${isDark ? 'text-light' : ''}`}>Patterns</Form.Label>
                    <Select
                      isMulti
                      name="patterns"
                      options={patternOptions}
                      value={patternOptions.filter(option => formData.patterns.includes(option.value))}
                      onChange={(selectedOptions, actionMeta) => handleMultiSelectChange(selectedOptions, actionMeta)}
                      styles={customSelectStyles}
                    />
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label className={`fw-bold ${isDark ? 'text-light' : ''}`}>Upload JPEG Image</Form.Label>
                    <div className={`d-flex justify-content-center align-items-center rounded-3 p-4 ${isDark ? 'bg-secondary' : 'bg-light'}`} style={{ cursor: 'pointer', border: `2px dashed ${isDark ? '#666' : '#ced4da'}` }}>
                      <label htmlFor="file-upload" className="mb-0" style={{ cursor: 'pointer' }}>
                        {file ? (
                          <img src={URL.createObjectURL(file)} alt="Preview" className="img-fluid rounded" style={{ maxHeight: '150px' }} />
                        ) : (
                          <div className="text-center">
                            <Upload size={48} className={`mb-2 ${isDark ? 'text-light' : 'text-primary'}`} />
                            <p className={isDark ? 'text-light' : 'text-muted'}>Click to upload or drag and drop</p>
                          </div>
                        )}
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        accept=".jpg,.jpeg"
                        onChange={handleFileChange}
                        className="d-none"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Button variant={isDark ? "outline-light" : "primary"} type="submit" className="w-100 mt-3 py-2 fw-bold">Submit Request</Button>
            </Form>
            
            {suggestedManufacturers.length > 0 && (
              <div className="mt-5">
                <h5 className={`mb-3 fw-bold ${isDark ? 'text-light' : ''}`}>Suggested Manufacturers:</h5>
                <Row>
                  {suggestedManufacturers.map((manufacturer, index) => (
                    <Col md={4} key={index} className="mb-3">
                      <Card className={`h-100 shadow-sm ${isDark ? 'bg-secondary text-light' : ''}`}>
                        <Card.Body>
                          <Card.Title className="fw-bold">{manufacturer.name}</Card.Title>
                          <Card.Text>
                            ID: {manufacturer.manufacturer_id}
                            <br />
                            Score: <span className={`fw-bold ${isDark ? 'text-info' : 'text-primary'}`}>{manufacturer.score.toFixed(2)}</span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default PomuForm;