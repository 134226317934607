import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { fetchAuthSession } from 'aws-amplify/auth';


export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(() => localStorage.getItem("dark") === "1");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [isAuthChecking, setIsAuthChecking] = useState(true);
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    document.body.classList.toggle("dark-mode", isDark);
  }, [isDark]);

  const getAuthToken = useCallback(async () => {
    if (!isAuthenticated) return null;
    try {
      const session = await fetchAuthSession();
      const token = session.tokens?.accessToken?.toString();
      setAuthToken(token);
      return token;
    } catch (error) {
      console.error('Error fetching auth token:', error);
      return null;
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      getAuthToken();
    } else {
      setAuthToken(null);
    }
  }, [isAuthenticated, getAuthToken]);

  const checkAuthStatus = useCallback(async () => {
    setIsAuthChecking(true);
    try {
      const user = await getCurrentUser();
      setIsAuthenticated(true);
      setUserEmail(user.signInDetails?.loginId || null);
    } catch (error) {
      setIsAuthenticated(false);
      setUserEmail(null);
    } finally {
      setIsAuthChecking(false);
    }
  }, []);

  useEffect(() => {
    checkAuthStatus();

    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          checkAuthStatus();
          break;
        case 'signOut':
          setIsAuthenticated(false);
          setUserEmail(null);
          setIsAuthChecking(false);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          setIsAuthenticated(false);
          setUserEmail(null);
          setIsAuthChecking(false);
          break;
        default:
          break;
      }
    });

    return () => unsubscribe();
  }, [checkAuthStatus]);

  const toggleDarkMode = useCallback(() => {
    setIsDark(prevIsDark => {
      const newIsDark = !prevIsDark;
      localStorage.setItem("dark", newIsDark ? "1" : "");
      return newIsDark;
    });
  }, []);

  const updateAuthStatus = useCallback((status, email = null) => {
    setIsAuthenticated(status);
    setUserEmail(email);
    setIsAuthChecking(false);
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await signOut({ global: true });
      updateAuthStatus(false);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  }, [updateAuthStatus]);

  return (
    <AppContext.Provider value={{
      isDark,
      toggleDarkMode,
      isAuthenticated,
      isAuthChecking,
      updateAuthStatus,
      handleLogout,
      authToken,
      getAuthToken,
      userEmail,
      checkAuthStatus
    }}>
      {children}
    </AppContext.Provider>
  );
};