import React, { useContext } from 'react';
import GlobalNavbar from './GlobalNavbar';
import GlobalFooter from './GlobalFooter';
import { AppContext } from '../AppContext';

const Layout = ({ children }) => {
  const { isDark } = useContext(AppContext);

  return (
    <div className={`d-flex flex-column min-vh-100 ${isDark ? 'dark-mode' : ''}`}>
      <GlobalNavbar />
      <main className="flex-grow-1">
        {children}
      </main>
      <GlobalFooter />
    </div>
  );
};

export default Layout;
