import React, { useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { motion, useAnimation } from 'framer-motion';
import { AppContext } from '../AppContext';
import '../App.css';
import { Star, Zap, Users, Shield, Clock, Globe } from 'lucide-react';
import LightBackgroundCollage from '../images/collages/Home_Background_Collage.jpg';
import DarkBackgroundCollage from '../images/collages/dark_home_background.jpg';
import { Upload, Edit, ThumbsUp } from 'lucide-react';

const MainPage = () => {
  const { isDark, isAuthenticated } = useContext(AppContext);
  const controls = useAnimation();
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start("visible");
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [controls]);

  const backgroundStyle = {
    backgroundImage: `url(${isDark ? DarkBackgroundCollage : LightBackgroundCollage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -1,
  };

  const heroStyle = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',
    paddingTop: '1rem', 
  };

  const contentStyle = {
    position: 'relative',
    zIndex: 1,
    minHeight: '100vh',
  };

  const cardStyle = {
    backgroundColor: isDark ? '#212121' : 'rgba(255, 255, 255, 1)',
    padding: '2.5rem',
    borderRadius: '20px',
    border: isDark ? '3px solid white' : '3px solid black',
    boxShadow: isDark 
      ? '0 0 15px rgba(255, 255, 255, 0.3)' 
      : '0 0 15px rgba(0, 0, 0, 0.3)',
    marginBottom: '2rem',
    maxWidth: '800px',
    width: '100%',
  };

  const fullWidthSectionStyle = {
    backgroundColor: isDark ? '#212121' : 'rgba(255, 255, 255, 1)',
    padding: '4rem 0',
    borderTop: isDark ? '3px solid white' : '3px solid black',
    borderBottom: isDark ? '3px solid white' : '3px solid black',
    width: '100vw',
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  };

  const headingStyle = {
    color: isDark ? 'white' : 'black',
    textShadow: isDark 
      ? '2px 2px 4px rgba(0,0,0,0.5)' 
      : '2px 2px 4px rgba(255,255,255,0.5)',
  };

  const enhancedHeadingStyle = {
    ...headingStyle,
    fontSize: '2.5rem',
    fontWeight: 'bold',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '0.5rem 1rem',
    borderBottom: `4px solid ${isDark ? 'white' : 'black'}`,
    display: 'inline-block',
    marginBottom: '2rem',
  };

  const paragraphStyle = {
    color: isDark ? 'white' : 'black',
    textShadow: isDark 
      ? '1px 1px 2px rgba(0,0,0,0.5)' 
      : '1px 1px 2px rgba(255,255,255,0.5)',
    fontSize: '1.1rem', // Increased font size
    lineHeight: '1.6', // Adjusted line height for better readability with larger font
  };


  const stepVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: i => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.3,
        duration: 0.5
      }
    })
  };

  const timelineContainerStyle = {
    position: 'relative',
    paddingLeft: '30px', // Increased to accommodate the timeline
    paddingTop: '10px',
    paddingBottom: '10px',
  };

  const timelineLineStyle = {
    position: 'absolute',
    left: '9px', // Adjusted to align with circles
    top: '0',
    bottom: '0',
    width: '3px',
    backgroundColor: isDark ? 'white' : 'black',
  };

  const timelineItemStyle = {
    position: 'relative',
    marginBottom: '1.5rem', // Reduced spacing between items
  };

  const timelineCircleStyle = {
    position: 'absolute',
    left: '-30px', // Adjusted to align with the line
    top: '0',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: isDark ? 'white' : 'black',
    border: `3px solid ${isDark ? '#2a2a2a' : '#f8f9fa'}`,
    zIndex: 1, // Ensure circle appears above the line
  };

  const steps = [
    { title: "Upload Design", icon: Upload, description: "Share a high-quality photo of your fashion design. Our system accepts various file formats for your convenience." },
    { title: "Describe Item", icon: Edit, description: "Provide detailed information about your design, including materials, sizing, quantity needed, and any special requirements." },
    { title: "Receive Recommendations", icon: ThumbsUp, description: "Get a curated list of top-tier manufacturers who are best suited to bring your design to life, complete with ratings and specialties." },
  ];

  const featureStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '2rem',
  };

  const iconStyle = {
    marginRight: '1rem',
    flexShrink: 0,
    backgroundColor: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    padding: '0.5rem',
    borderRadius: '50%',
  };

  return (
    <div className={`App ${isDark ? 'dark-mode' : ''}`} style={{ overflow: 'hidden' }}>
      <div style={backgroundStyle}></div>
      
      {/* Hero Section */}
      <section style={heroStyle}>
        <Container className="d-flex flex-column justify-content-center align-items-center h-100">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            style={cardStyle}
          >
            <h2 className="display-4 fw-bold mb-4" style={headingStyle}>
              Find Top-Rated Manufacturers Tailored to Your Needs
            </h2>
            <p className="lead mb-5" style={paragraphStyle}>
              Pomu's AI matches fashion designers with ideal manufacturing partners. 
              Streamline your production and bring designs to life effortlessly.
            </p>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Button 
                variant={isDark ? "outline-light" : "outline-dark"}
                size="lg" 
                as={Link} 
                to={isAuthenticated ? "/sketch" : "/create-account"}
                className="px-5 py-3"
              >
                {isAuthenticated ? "Start Matching" : "Join Pomu"}
              </Button>
            </motion.div>
            <p className="mt-3 mb-0" style={{ ...paragraphStyle, fontWeight: 'bold' }}>
              Scroll to learn more
            </p>
          </motion.div>
        </Container>
      </section>
  
      {/* What is Pomu Section */}
      <motion.section
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
        style={{...fullWidthSectionStyle, marginBottom: '2rem'}}
      >
        <Container>
          <div className="text-center">
            <motion.h2 
              style={enhancedHeadingStyle}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              What is Pomu?
            </motion.h2>
          </div>
          <Row className="justify-content-center">
            {[
              { title: "Connecting Designers and Manufacturers", content: "Pomu bridges the gap between fashion designers and top-tier manufacturers, simplifying the process of finding the perfect production partner for your designs." },
              { title: "Precision Pairing System", content: "Our innovative matching system analyzes your design requirements and connects you with manufacturers who excel in creating similar items, ensuring an ideal fit for your unique production needs." },
              { title: "Streamlined Process", content: "With Pomu, you can focus on what you do best - designing. We handle the complexities of manufacturer sourcing, allowing you to bring your creations to life efficiently and effortlessly." }
            ].map((item, index) => (
              <motion.div
                key={index}
                custom={index}
                variants={stepVariants}
                className="col-md-4 mb-4"
              >
                <motion.div 
                  className="p-4 rounded text-center" 
                  style={{
                    backgroundColor: isDark ? '#2a2a2a' : '#f8f9fa',
                    border: `3px solid ${isDark ? 'white' : 'black'}`,
                    transition: 'all 0.3s ease-in-out',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: isDark 
                      ? '0 0 30px rgba(255, 255, 255, 0.2)' 
                      : '0 0 30px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <h3 style={{...headingStyle, fontSize: '1.8rem'}}>{item.title}</h3>
                  <p style={{...paragraphStyle, marginTop: '1rem'}}>{item.content}</p>
                </motion.div>
              </motion.div>
            ))}
          </Row>
        </Container>
      </motion.section>
  
      {/* How Pomu Works Section */}
      {/* How Pomu Works Section */}
<motion.section
  ref={ref}
  initial="hidden"
  animate={controls}
  style={{...fullWidthSectionStyle, marginBottom: '2rem', padding: '4rem 0'}}
>
  <Container>
    <div className="text-center mb-5">
      <motion.h2 
        style={{...enhancedHeadingStyle, fontSize: '3rem', marginBottom: '1rem'}}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        How Pomu Works
      </motion.h2>
      <motion.p
        style={{...paragraphStyle, fontSize: '1.4rem', maxWidth: '800px', margin: '0 auto'}}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Three simple steps to bring your designs to life
      </motion.p>
    </div>
    <Row className="justify-content-center">
      <Col xs={12}>
        <motion.div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
          }}
          whileHover={{ scale: 1.02 }}
        >
          {steps.map((step, index) => (
            <motion.div 
              key={index} 
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '30%',
                margin: '0 1rem 2rem',
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div style={{
                width: '120px',
                height: '120px',
                borderRadius: '50%',
                backgroundColor: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `4px solid ${isDark ? 'white' : 'black'}`,
                marginBottom: '1.5rem',
                position: 'relative',
              }}>
                <step.icon size={60} color={isDark ? 'white' : 'black'} />
                <div style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundColor: isDark ? 'white' : 'black',
                  color: isDark ? 'black' : 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}>
                  {index + 1}
                </div>
              </div>
              <h3 style={{
                ...headingStyle, 
                fontSize: '1.6rem', 
                textAlign: 'center',
                marginBottom: '0.5rem',
                fontWeight: 'bold',
              }}>
                {step.title}
              </h3>
              <div style={{
                width: '50px',
                height: '4px',
                backgroundColor: isDark ? 'white' : 'black',
                marginTop: '0.5rem',
              }}></div>
            </motion.div>
          ))}
        </motion.div>
      </Col>
    </Row>
  </Container>
</motion.section>
  
      {/* Why Choose Pomu Section */}
      <motion.section
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
        style={{
          ...fullWidthSectionStyle,
          backgroundColor: isDark ? '#1a1a1a' : '#f0f0f0',
          borderTop: `3px solid ${isDark ? 'white' : 'black'}`,
          borderBottom: `3px solid ${isDark ? 'white' : 'black'}`,
          position: 'relative',
          overflow: 'hidden',
          padding: '6rem 0',
        }}
      >
        <Container>
          <div className="text-center">
            <motion.h2 
              style={{...enhancedHeadingStyle, fontSize: '3rem', marginBottom: '3rem'}}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              Why Choose Pomu?
            </motion.h2>
          </div>
          <Row className="justify-content-center">
            <Col lg={10}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                style={{
                  padding: '2rem',
                  borderRadius: '10px',
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                <Row>
                  <Col md={6}>
                    <div style={featureStyle}>
                      <Star size={40} color={isDark ? 'white' : 'black'} style={iconStyle} />
                      <div>
                        <h3 style={{...headingStyle, fontSize: '1.6rem', marginBottom: '0.5rem'}}>Elite Manufacturer Network</h3>
                        <p style={paragraphStyle}>
                          Connect with top-tier manufacturers vetted for excellence in craftsmanship, ethical practices, and versatility in design execution.
                        </p>
                      </div>
                    </div>
                    <div style={featureStyle}>
                      <Zap size={40} color={isDark ? 'white' : 'black'} style={iconStyle} />
                      <div>
                        <h3 style={{...headingStyle, fontSize: '1.6rem', marginBottom: '0.5rem'}}>AI-Powered Precision Matching</h3>
                        <p style={paragraphStyle}>
                          Our advanced AI system ensures you're paired with manufacturers perfectly suited to bring your unique vision to life.
                        </p>
                      </div>
                    </div>
                    <div style={featureStyle}>
                      <Users size={40} color={isDark ? 'white' : 'black'} style={iconStyle} />
                      <div>
                        <h3 style={{...headingStyle, fontSize: '1.6rem', marginBottom: '0.5rem'}}>Collaborative Platform</h3>
                        <p style={paragraphStyle}>
                          Seamlessly communicate with manufacturers, share designs, and track progress all in one place.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div style={featureStyle}>
                      <Shield size={40} color={isDark ? 'white' : 'black'} style={iconStyle} />
                      <div>
                        <h3 style={{...headingStyle, fontSize: '1.6rem', marginBottom: '0.5rem'}}>Quality Assurance</h3>
                        <p style={paragraphStyle}>
                          Benefit from our rigorous quality control processes, ensuring your designs are produced to the highest standards.
                        </p>
                      </div>
                    </div>
                    <div style={featureStyle}>
                      <Clock size={40} color={isDark ? 'white' : 'black'} style={iconStyle} />
                      <div>
                        <h3 style={{...headingStyle, fontSize: '1.6rem', marginBottom: '0.5rem'}}>Time-Efficient Process</h3>
                        <p style={paragraphStyle}>
                          Save valuable time with our streamlined matching process, allowing you to focus more on creativity and less on logistics.
                        </p>
                      </div>
                    </div>
                    <div style={featureStyle}>
                      <Globe size={40} color={isDark ? 'white' : 'black'} style={iconStyle} />
                      <div>
                        <h3 style={{...headingStyle, fontSize: '1.6rem', marginBottom: '0.5rem'}}>Global Reach</h3>
                        <p style={paragraphStyle}>
                          Access a worldwide network of manufacturers, opening up diverse production possibilities for your designs.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </motion.div>
            </Col>
          </Row>
        </Container>
        {/* Background decorative elements */}
        <svg width="100%" height="100%" style={{position: 'absolute', top: 0, left: 0, zIndex: 0, opacity: 0.05}}>
          <pattern id="pattern" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
            <circle cx="20" cy="20" r="1" fill={isDark ? 'white' : 'black'} />
          </pattern>
          <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern)" />
        </svg>
      </motion.section>
    </div>
  );
};

export default MainPage;
